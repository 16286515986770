import * as Yup from 'yup'
export interface SalesModel {
  foto: any
  npp_lead: string
  type: string
  npp: string
  name: string
  email: string
  phone: string
  address_id_card: string
  address_domicile: string
  place_of_birth: string
  date_of_birth: string
  id_card: string
  npwp: string
  biological_mother_name: string
  residence: string
  length_of_stay: string
  family_name: string
  family_address: string
  family_connection: string
  family_phone: string
  bank: string
  account_number: string
  family_card_number: string
  last_education: string
  owned_vehicle: string
  position_id: number
  banking_id: number
  banking_area_id: number
  status: number
  is_active: boolean
  role_id: number
  approval: Approval[]
}

export interface Approval {
  type : string,
  npp_user:  string,
  is_status: boolean,
}

export const Value: SalesModel = {
  npp_lead: "",
  type: "",
  npp: "",
  foto: "",
  name: "",
  email: "",
  phone: "",
  address_id_card: "",
  address_domicile: "",
  place_of_birth: "",
  id_card: "",
  npwp: "",
  biological_mother_name: "",
  residence: "",
  length_of_stay: "",
  family_name: "",
  family_address: "",
  family_connection: "",
  family_phone: "",
  bank: "",
  account_number: "",
  family_card_number: "",
  last_education: "",
  owned_vehicle: "",
  date_of_birth: "",
  position_id: 4,
  banking_id: 0,
  banking_area_id: 0,
  status: 0,
  is_active: false,
  role_id: 3,
  approval: []
}

export const DataSchema = Yup.object().shape({
  npp_lead: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maximum 50 karakter')
    .required('npp atasan Harus di isi'),
  name: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(100, 'Maximum 50 karakter')
    .required('name Harus di isi'),
  type: Yup.string()
    .required('anda harus memilih type'),
})
