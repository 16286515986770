/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react'
import { DateRangePickerComponent } from '../../../partials/date/Range'

export function FilterMonth({onfilter}) {
    const [datePickerSelect, setDatePickerSelect] = useState<boolean>(false)

    const SelectData = (x) => {
        setDatePickerSelect(x)
    }

    const onFilterApplay = () => {
        onfilter(datePickerSelect)
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Filter By Date Created:</label>
                    <DateRangePickerComponent getSelect={SelectData} />
                </div>


                <div className='d-flex justify-content-end'>
                  

                    <button onClick={onFilterApplay} type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}
