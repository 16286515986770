import React from 'react';

interface StatusPageDebiturProps {
  status: number;
}

const StatusPageDebitur: React.FC<StatusPageDebiturProps> = ({ status }) => {
  let statusText;
  let statusColorClass;

  switch (status) {
    case 1:
      statusText = "Collect Data";
      statusColorClass = "badge badge-light-danger"; // Blue color in Bootstrap
      break;
    case 2:
      statusText = "Proses";
      statusColorClass = "badge badge-light-warning"; // Yellow color in Bootstrap
      break;
    case 3:
      statusText = "Approved";
      statusColorClass = "badge badge-light-success"; // Green color in Bootstrap
      break;
    case 4:
      statusText = "Akad";
      statusColorClass = "badge badge-light-info"; // Light blue color in Bootstrap
      break;
    case 5:
      statusText = "Booking";
      statusColorClass = "badge badge-light-primary"; // Blue color in Bootstrap (you can choose a different color if needed)
      break;
    case 6:
      statusText = "Cancel";
      statusColorClass = "badge badge-light-danger"; // Red color in Bootstrap
      break;
    default:
      statusText = "Status Belum DI tentukan";
      statusColorClass = "badge badge-light-muted"; // Gray color in Bootstrap
      break;
  }

  return (
    <span className={statusColorClass}>{statusText}</span>
  );
};

export default StatusPageDebitur;