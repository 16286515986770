/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTIcon, formatToRupiahIDN } from '../../../../_metronic/helpers'
import { FilterYear } from '../../../../_metronic/partials/content/dropdown/FilterYear'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import axios from 'axios';
import { CountPayement } from '../core/_requests'

type Props = {
    className: string
}

const Payment: React.FC<Props> = ({ className }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const [arrMonth, setArrMonth] = useState<string[]>([])
    const [arrPengeluaran, setArrPengeluaran] = useState<number[]>([])
    const [arrPemasukan, setArrPemasukan] = useState<number[]>([])

    const fetchData = async (year: number) => {

        try {
            const request = await CountPayement(year);

            const month: string[] = [];
            const nilai: number[] = [];
            const pengeluaran: number[] = [];
            for (const i of request.data) {
                month.push(i.month_name);
                nilai.push(i.pemasukan);
                pengeluaran.push(i.pengeluaran);
            }

            setArrMonth(month)
            setArrPengeluaran(nilai)
            setArrPemasukan(pengeluaran)


            console.log(request)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };
    const onchange = async (x) => {
        fetchData(parseInt(x));
    };

    useEffect(() => {
        fetchData(0);
    }, [mode]);

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, arrMonth])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, arrMonth, arrPengeluaran, arrPemasukan))
        if (chart) {
            chart.render()
        }

        return chart
    }

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Laporan Pendapatan Perbulan</span>

                    {/* <span className='text-muted fw-semibold fs-7'>20 Sales Organik , 30 Sales Nonorganik</span> */}
                </h3>
                {/* end::Title */}

                {/* begin::Toolbar */}
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <FilterYear onchange={onchange} />
                    {/* end::Menu */}
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { Payment }

function getChartOptions(height: number, arrMonth: any, arrPengeluaran: any, arrPemasukan: any): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-primary')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')

    return {
        series: [
            {
                name: 'Pengeluaran',
                data: arrPemasukan,
            },
            {
                name: 'Pemasukan',
                data: arrPengeluaran,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: arrMonth,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return `Rp${formatToRupiahIDN(val.toString())}`
                },
            },
        },
        colors: [baseColor, secondaryColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
