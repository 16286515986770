import { KTIcon, toAbsoluteUrl } from '../../../helpers'

export function Wa() {

  return (

    <a href="https://wa.me/1234567890" rel="noreferrer" target="_blank" style={{
        position: "fixed",
        bottom: "120px",
        right: "10px",
        width: "50px",
        height: "auto",
        cursor: "pointer",
    }}>
      <img src={toAbsoluteUrl('/media/whatsapp.png')} alt='Metronic' width={"50px"} />
    </a>
  )
}
