import React, {useState} from 'react'
import { Tabel } from './cards/Tabel'
import { FormFile } from './cards/FormFile'
import { useParams } from "react-router-dom";


export const Lampiran = ({type}) => {
  const { id } = useParams();

  const [tableData, setTableData] = useState([]); // State to store table data

  // Function to update the table data when the form is submitted
  const handleFormSubmit = (newData) => {
    setTableData(newData);
  };

  

  return (
    <>
      <FormFile id={id} inputType={type} onFormSubmit={handleFormSubmit}/>
      <Tabel className='' id={id} inputType={type} dataReload={tableData} />
    </>
  )
}
