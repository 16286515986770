/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTIcon, toImgaesURL , handleImageError} from '../../../helpers'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router'
import { GetDetail } from './core/_request';
import { RequestDetail } from './core/_model';



type Props = {
  path_detail: string
  path_lampiran: string
  path_debitur: string
}
const HeaderSalesNonOrganic: React.FC<Props> = ({
  path_detail,
  path_lampiran,
  path_debitur,
}) => {
  const location = useLocation()
  const { id } = useParams();
  const [dataSales, setdataSales] = useState<RequestDetail>()

  const getDetails = async () => {
    try {
      const response = await GetDetail(id);
      if (response.status) {

        setdataSales(response)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDetails();
  }, [])



  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={toImgaesURL(dataSales?.data.detail.foto)}
                alt={dataSales?.data.detail.name}
                onError={(event) => {(handleImageError(event))}}
              />
              <div className={`position-absolute translate-middle bottom-0 start-100 mb-6 ${dataSales?.data.detail.status === 1 ? 'bg-success' : 'bg-danger'}  rounded-circle border border-4 border-white h-20px w-20px`}></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {dataSales?.data.detail.name}
                  </a>
                  <a href='#'>
                    {dataSales?.data.detail.status === 1 ?
                      <KTIcon iconName='verify' className='fs-1 text-success' />
                      :
                      <KTIcon iconName='cross-square' className='fs-1 text-danger' />
                    }
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    {dataSales?.data.detail.insentif}%
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {dataSales?.data.detail.phone}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {dataSales?.data.detail.email}

                  </a>

                </div>
              </div>

              <div className='d-flex my-4'>
                {/* <Link to={"/sales"} className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Kembali</span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === path_detail + id && 'active')
                }
                to={`${path_detail}${id}`}
              >
                Detail
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === path_lampiran + id && 'active')
                }
                to={`${path_lampiran}${id}`}
              >
                Lampiran
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === path_debitur + id && 'active')
                }
                to={`${path_debitur}${id}`}
              >
                Debitur
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { HeaderSalesNonOrganic }
