import axios, { AxiosResponse } from 'axios'
import {ProfileRespone} from './_model'

const API_URL = process.env.REACT_APP_API_URL

export const PROFILEENDPOINT = `${API_URL}/auth/profile`
export const RESETPAS = `${API_URL}/auth/reset-password`

export const GetProfileDetail = async (): Promise<ProfileRespone> => {
  return axios
    .get(`${PROFILEENDPOINT}`)
    .then((d: AxiosResponse<ProfileRespone>) => d.data)
}

export const ResetPassword = async (npp: string): Promise<ProfileRespone> => {
  return axios
    .get(`${RESETPAS}/${npp}`)
    .then((d: AxiosResponse<ProfileRespone>) => d.data)
}

