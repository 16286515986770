import * as Yup from 'yup'
export interface LampiranModel {
  name: string
  type: string
  relasi_id: string
  file: string
}

export const Value: LampiranModel = {
  name: "",
  type: "",
  relasi_id: "",
  file: "",
}

export interface SearchModel {
  page: number
  limit: number
  search: Search[]
}

export interface Search {
  key: string
  parameter: string
  condition: string
}

export interface ListModel {
  status: boolean
  message: string
  error: string
  data: Data
}

export interface Data {
  limit: number
  page: number
  sort: string
  total_rows: number
  total_pages: number
  rows: Row[]
}

export interface Row {
  name: string
  file: string
  id: number
}

export const DataSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nama Harus di isi')
})
