
import { StatisticsWidget5 } from '../../../../_metronic/partials/widgets';
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CountSalesAVR } from '../core/_requests';


const CardTop: React.FC = () => {
    const [data, setData] = useState<any>()

    const fetchData = async () => {
        try {
            const request = await CountSalesAVR();
            console.log(request.data)
            setData(request);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };


    useEffect(() => {

        fetchData()
    }, []);



    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-xl-6'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='cheque'
                    color='#2596be'
                    iconColor='white'
                    title={data?.data?.Count}
                    titleColor='white'
                    description={'Total Sales Non Organic'}
                    descriptionColor='white'
                />

            </div>

            <div className='col-xl-6'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='briefcase'
                    color='#1b67a5'
                    iconColor='white'
                    title={`${data?.data?.RataRata}%`}
                    titleColor='white'
                    description={'Total Rata-Rata Insentif Sales Non Organic'}
                    descriptionColor='white'
                />
            </div>
        </div>
    )
}

export { CardTop }
