import axios, { AxiosResponse } from 'axios'
import { RegisterCount, PaymentCount, ListSalesKpi, SearchModel, ListModel, CountDataDebiturStatus, ListSalesNon } from './_model'
const API_URL = process.env.REACT_APP_API_URL + '/dashboard'
export const COUNTDEBITURREGISTER = `${API_URL}/count-debitur-register`
export const COUNTDEBITURSTATUS = `${API_URL}/count-debitur-status`
export const COUNTSALESNONORGANIC = `${API_URL}/count-sales-non-organic`
export const COUNTSALESKPI = `${API_URL}/count-sales-kpi`
export const COUNTPAYMENT = `${API_URL}/count-payment`
export const COUNTSALESAVR = `${API_URL}/count-sales-avr`

export const CountDebiturRegister = async (year: number): Promise<RegisterCount> => {
  return axios
    .get(`${COUNTDEBITURREGISTER}/${year}`)
    .then((d: AxiosResponse<RegisterCount>) => d.data)
    .catch((err) => err?.response?.data)
}
export const CountSalesAVR = async (): Promise<any> => {
  return axios
    .get(`${COUNTSALESAVR}`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((err) => err?.response?.data)
}

export const CountPayement = async (year: number): Promise<PaymentCount> => {
  return axios
    .get(`${COUNTPAYMENT}/${year}`)
    .then((d: AxiosResponse<PaymentCount>) => d.data)
    .catch((err) => err?.response?.data)
}

export const CountDebiturStatus= async (start: string, end: string): Promise<CountDataDebiturStatus> => {
  return axios
    .get(`${COUNTDEBITURSTATUS}?start=${start}&end=${end}`)
    .then((d: AxiosResponse<CountDataDebiturStatus>) => d.data)
    .catch((err) => err?.response?.data)
}

export const CountSalesNonOrganic = async (start: string, end: string): Promise<ListSalesNon> => {
  return axios
    .get(`${COUNTSALESNONORGANIC}?start=${start}&end=${end}`)
    .then((d: AxiosResponse<ListSalesNon>) => d.data)
    .catch((err) => err?.response?.data)
}
export const CountSaleskpi = async (): Promise<ListSalesKpi> => {
  return axios
    .get(`${COUNTSALESKPI}`)
    .then((d: AxiosResponse<ListSalesKpi>) => d.data)
    .catch((err) => err?.response?.data)
}


const API_URL_DEBITUR = process.env.REACT_APP_API_URL + '/debitur'

export const LIST = `${API_URL_DEBITUR}/list`

export const GetDebitur = async (requestBody: SearchModel): Promise<ListModel> => {
  return axios
    .post(`${LIST}`, requestBody)
    .then((d: AxiosResponse<ListModel>) => d.data)
    .catch((err) => err?.response?.data);
}