/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';

import { ProfileRespone } from '../../../../helpers/global-fetch/_model'
import { GetProfileDetail } from '../../../../helpers/global-fetch/profile'
import axios from 'axios'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const npp = Cookies.get('npp');
  const role = Cookies.get('role');
  const [cookies, setCookie] = useCookies(['menu', 'bank']);


  // get menu by profile

  const [data, setData] = useState<ProfileRespone>()
  const fetchData = async () => {
    try {
      const request = await GetProfileDetail();
      console.log(request)
      setData(request);
      setCookie("menu", request?.data.role.menu ?? []);
      setCookie("bank", request?.data.profile.banking.name ?? []);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errResp = error.response;
        console.log(errResp?.data)
      }
    }
  };

  useEffect(() => {
    fetchData()
  }, []);

  console.log(role)
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/simulasi/'
        title='Simulasi'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/simulasi/bfp' title='Simulasi BFP' hasBullet={true} />
        <SidebarMenuItemWithSub to='/simulasi/gpt/satu' title='Simulasi GP THT' hasBullet={true}>
          <SidebarMenuItem to='/simulasi/gpt/satu' title='Satu' hasBullet={true} />

          {/* <SidebarMenuItem to='/simulasi/gpt/satu' title='Satu' hasBullet={true} /> */}
          <SidebarMenuItem to='/simulasi/combo' title='Combo' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{data?.data.role.name}</span>
        </div>
      </div>

      {role === "3" ?
        <>
          <SidebarMenuItem
            to={'sales/sales-profile/' + npp}
            icon='abstract-28'
            title={"sales"}
            fontIcon='bi-app-indicator'
          />
        </>
        : ""
      }

      {role === "4" ?
        <>
          <SidebarMenuItem
            to={'lead-sales/sales-profile/' + npp}
            icon='abstract-28'
            title={"sales"}
            fontIcon='bi-app-indicator'
          />
        </>
        : ""
      }

      {role !== "3" ?
        <>
          {data?.data.role.menu.map((item) => (
            <>

              {item.path !== '#!' ? <>
                <SidebarMenuItem key={item.id} to={item.path} icon={item.icon} title={item.name} fontIcon='bi-archive' />
              </>
                :
                <SidebarMenuItemWithSub to='#!' title='Master' fontIcon='bi-sticky' icon='cross-circle'>
                  <SidebarMenuItem to='/position' title='Jabatan' hasBullet={true} />
                  <SidebarMenuItem to='/banking' title='Bank' hasBullet={true} />
                  <SidebarMenuItem to='/area-banking' title='Bank Wilayah' hasBullet={true} />
                  <SidebarMenuItem to='/branch-banking' title='Bank Cabang' hasBullet={true} />
                  <SidebarMenuItem to='/company' title='Kantor' hasBullet={true} />
                  <SidebarMenuItem to='/master-simulasi' title='Simulasi' hasBullet={true} />
                </SidebarMenuItemWithSub>
              }
            </>
          ))}
        </>
        : ""
      }



    </>


  )
}

export { SidebarMenuMain }
