import React, { useState } from 'react'

export function FilterDate({onchange}) {
  const [selectedYear, setSelectedYear] = useState('');

    const handleYearChange = (e) => {


        const year = e.target.value;
        setSelectedYear(year);
    };

    const SubmitFilter = () => {


        onchange(selectedYear)
    };
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Filter Date:</label>
          <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
            <input
              className='form-control'
              type='date'
              value={selectedYear}
              onChange={handleYearChange}
            />
          </label>
        </div>

        <div className='d-flex justify-content-end'>
          <button onClick={SubmitFilter} type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
