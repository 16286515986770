import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangePickerComponent = ({ getSelect }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    const formattedStartDate = ranges.selection.startDate.toISOString().split('T')[0];
    const formattedEndDate = ranges.selection.endDate.toISOString().split('T')[0];
    setSelectionRange(ranges.selection);
    getSelect({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      key: 'selection',
    })
  };


  const handleShowDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  return (
    <div>
      <button
        type='button'
        onClick={handleShowDatePicker}
        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6 mt-2'
      >
        {!showDatePicker ? 'Pilih Tanggal' : 'Selesai'}
      </button>
      <br></br>

      {showDatePicker ?
        <>
          <div style={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            position: 'absolute'
          }}>
            <DateRange
              ranges={[selectionRange]}
              onChange={handleSelect}

            />
          </div>
        </> :
        <></>

      }

    </div>
  )
}
export { DateRangePickerComponent }
