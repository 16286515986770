import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LampiranModel, Value as initialValues, DataSchema } from '../core/_model'
import { SubmitCreate } from '../core/_request'
import { toast, ToastContainer } from "react-toastify"
import clsx from 'clsx'

type Props = {
  id: any
  inputType: string
  onFormSubmit: any
}

const FormFile: React.FC<Props> = ({
  id,
  inputType,
  onFormSubmit
}) => {

  const [loading, setLoading] = useState(false)
  const [base64Image, setBase64Image] = useState<string | null>(null);

  const formik = useFormik<LampiranModel>({
    initialValues,
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      setLoading(true)
      values.type = inputType
      values.relasi_id = id
      values.file = base64Image ?? ''
      const result = await SubmitCreate(values)
      setLoading(false)
      if (result?.status) {
        toast.success(result?.message);
        onFormSubmit()
      } else {
        toast.error(result?.message);
      }
    },
  })

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = e.target?.result as string;
        setBase64Image(base64Data);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Upload</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form
          className='form p-4'
          noValidate
          onSubmit={formik.handleSubmit}
        >

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          <div className='card-body border-top p-9'>


            <div className='row'>
              <div className='row col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nama File</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    placeholder='Nama File'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Upload File</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    placeholder='file'
                    type='file'
                    autoComplete='off'
                    onChange={handleFileUpload}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.file && formik.errors.file,
                      },
                      {
                        'is-valid': formik.touched.file && !formik.errors.file,
                      }
                    )}
                  />
                  {formik.touched.file && formik.errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.file}</span>
                      </div>
                    </div>
                  )}
                  {base64Image && (
                    <div className='mt-2'>
                      <img style={{
                        width: '10%',
                        height: '10%'
                      }} src={base64Image} alt="foto upload users admin" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>


          {/* begin::Form group */}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Simpan'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export { FormFile }
