import React, { useState, useEffect } from 'react'
import { SalesModel, Value as initialValues, DataSchema } from '../core/_model'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { GetDropdownBanking, GetDropdownAreaBanking } from '../../../../helpers/global-fetch/dropdown'
import { DataDrowpdown } from '../../../../helpers/global-fetch/_model'
import { SubmitUpdate, GetDetail } from '../core/_request';
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { dateNowDMYSubmit } from '../../../../helpers/dateHelpers'
import Cookies from 'js-cookie';

const UpdateSalesNonOrganic = () => {
  const [banking, setBanking] = useState<DataDrowpdown[]>()
  const [bankingArea, setBankingArea] = useState<DataDrowpdown[]>()
  const [showApproval, setshowApproval] = useState<boolean>()
  const navigate = useNavigate();
  const { id } = useParams();
  const npp = Cookies.get('npp');



  const [loading, setLoading] = useState(false)
  const formik = useFormik<SalesModel>({
    initialValues,
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      setLoading(true)
      values.position_id = 4
      values.phone = values.phone.toString()
      values.id_card = values.id_card.toString()
      values.npwp = values.npwp.toString()
      values.npp_lead = values.npp_lead.toString()
      values.length_of_stay = values.length_of_stay.toString()
      values.banking_id = values.banking_id == null ? 0 : parseInt(values.banking_id.toString())
      values.banking_area_id = values.banking_area_id == null ? 0 : parseInt(values.banking_area_id.toString());
      values.is_active = values.is_active.toString() === 'true' ? true : false;
      values.status = parseInt(values.status.toString())

      const result = await SubmitUpdate(values, id)
      setLoading(false)

      if (result?.status) {
        toast.success(result?.message);
        setTimeout(() => {
          navigate('/non-organik/views');
        }, 1000)
      } else {
        toast.error(result?.message);
      }
    },
  })


  const GetBanking = async () => {
    try {
      const response = await GetDropdownBanking();
      setBanking(response?.data ?? [])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const GetBankingArea = async (x: any, data_id = 0) => {
    try {
      formik.setFieldValue('banking_id', x);
      const response = await GetDropdownAreaBanking(x);
      setBankingArea(response?.data ?? [])
      if (data_id !== 0) {
        formik.setFieldValue("banking_area_id", data_id)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDetails = async () => {
    try {
      const response = await GetDetail(id);
      if (response.status) {

        GetBankingArea(response.data?.detail?.banking_id, response.data?.detail?.banking_area_id)
        formik.setValues(response.data.detail ?? {});


        let viewApproval = false;
        for (const item of response?.data?.approval) {
          if (item.npp_user === npp) {
            viewApproval = true;
            break;
          }
        }
        setshowApproval(viewApproval);

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("ini npp cuy", npp)

    GetBanking();
    getDetails();
  }, []);

  return (
    <>
      <form
        className='form p-4'
        noValidate
        onSubmit={formik.handleSubmit}
      >

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='row fv-row mb-8'>
          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span className="required">Nomor Kartu Keluarga</span>
            </label>

            <input
              placeholder='Nomor Kartu Keluarga'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('family_card_number')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.family_card_number && formik.errors.family_card_number,
                },
                {
                  'is-valid': formik.touched.family_card_number && !formik.errors.family_card_number,
                }
              )}
            />
            {formik.touched.family_card_number && formik.errors.family_card_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.family_card_number}</span>
                </div>
              </div>
            )}
          </div>


          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Type Sales </span>
            </label>


            <select
              {...formik.getFieldProps('type')}
              className='form-control bg-transparent'
            >
              <option value={''}>Pilih Type Sales</option>
              <option value={'Internal'}>Internal</option>
              <option value={'External'}>External</option>
            </select>

            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">NPP Atasan</span>
                </label>
                <input
                  placeholder='NPP Atasan'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('npp_lead')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.npp_lead && formik.errors.npp_lead,
                    },
                    {
                      'is-valid': formik.touched.npp_lead && !formik.errors.npp_lead,
                    }
                  )}
                />
                {formik.touched.npp_lead && formik.errors.npp_lead && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.npp_lead}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Nama</span>
                </label>
                <input
                  placeholder='Nama User'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Nomor NIK</span>
                </label>

                <input
                  placeholder='nomor NIK'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('id_card')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.id_card && formik.errors.id_card,
                    },
                    {
                      'is-valid': formik.touched.id_card && !formik.errors.id_card,
                    }
                  )}
                />
                {formik.touched.id_card && formik.errors.id_card && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.id_card}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Nomor NPWP</span>
                </label>

                <input
                  placeholder='nomor NPWP'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('npwp')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.npwp && formik.errors.npwp,
                    },
                    {
                      'is-valid': formik.touched.npwp && !formik.errors.npwp,
                    }
                  )}
                />
                {formik.touched.npwp && formik.errors.npwp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.npwp}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Email</span>
                </label>

                <input
                  placeholder='Nama Email'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.email && formik.errors.email,
                    },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Nomor Handphone</span>
                </label>

                <input
                  placeholder='Nomor Handphone'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('phone')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.phone && formik.errors.phone,
                    },
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className='col-md-12 mt-3'>

            <label className='form-label fw-bolder text-dark fs-6'>
              Pilih Penempatan Sales
            </label>

            <select
              {...formik.getFieldProps('banking_id')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.banking_id && formik.errors.banking_id,
                },
                {
                  'is-valid': formik.touched.banking_id && !formik.errors.banking_id,
                }
              )}
              onChange={(e) => GetBankingArea(e.target.value)} // Set the selected value in state
            >
              <option value=''>Pilih Banking</option>
              {banking?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))
              }


            </select>
            {formik.touched.banking_id && formik.errors.banking_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.banking_id}</div>
              </div>
            )}
          </div>

          <div className='col-md-12 mt-10'>

            <label className='form-label fw-bolder text-dark fs-6'>
              Pilih Wilayah Penempatan
            </label>

            <select
              {...formik.getFieldProps('banking_area_id')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.banking_area_id && formik.errors.banking_area_id,
                },
                {
                  'is-valid': formik.touched.banking_area_id && !formik.errors.banking_area_id,
                }
              )}
            >
              <option value=''>Pilih Area Banking</option>
              {bankingArea?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))
              }


            </select>
            {formik.touched.banking_area_id && formik.errors.banking_area_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.banking_area_id}</div>
              </div>
            )}
          </div>



          <div className="fv-row mt-10 mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Alamat Sesuai KTP</span>
            </label>

            <input
              placeholder='Alamat Sesuai KTP'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('address_id_card')}
              className='form-control bg-transparent'
            />
          </div>


          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Alamat Sesuai Domisili</span>
            </label>

            <input
              placeholder='Alamat Sesuai KTP'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('address_domicile')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Tempat Lahir</span>
                </label>

                <input
                  placeholder='Tempat Lahir'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('place_of_birth')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Tanggal Lahir</span>
                </label>

                <input
                  placeholder='Tanggal Lahir'
                  type='date'
                  autoComplete='off'
                  {...formik.getFieldProps('date_of_birth')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>

          </div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Nama Ibu Kandung</span>
            </label>

            <input
              placeholder='Nama Ibu Kandung'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('biological_mother_name')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Status Tempat Tinggal </span>
                </label>


                <select
                  {...formik.getFieldProps('residence')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Status Tempat Tinggal</option>
                  <option value={'Sewa'}>Sewa</option>
                  <option value={'Sendiri'}>Sendiri</option>
                  <option value={'Orang Tua'}>Orang Tua</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Lama Tinggal</span>
                </label>

                <input
                  placeholder='Lama Tinggal'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('length_of_stay')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>
          </div>

          <div className='border border-1 p-10 mb-10'>
            <h6>Kontak Keluarga</h6>
            <hr></hr>
            <div className='row mb-10'>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nama Keluarga</span>
                  </label>

                  <input
                    placeholder='Nama Keluarga'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_name')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Hubungan </span>
                  </label>


                  <select
                    {...formik.getFieldProps('family_connection')}
                    className='form-control bg-transparent'
                  >
                    <option value={''}>Pilih Hubungan</option>
                    <option value={'Orang Tua'}>Orang Tua</option>
                    <option value={'Saudara Kandung'}>Saudara Kandung</option>
                    <option value={'Anak'}>Anak</option>
                    <option value={'Kake'}>Kake</option>
                    <option value={'Nenek'}>Nenek</option>
                    <option value={'Paman'}>Paman</option>
                    <option value={'Bibi'}>Bibi</option>
                    <option value={'Sepupu'}>Sepupu</option>
                    <option value={'Mertua'}>Mertua</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='row mb-10'>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Alamat</span>
                  </label>

                  <input
                    placeholder='Alamat'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_address')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nomor Handphone</span>
                  </label>

                  <input
                    placeholder='Nomor Handphone'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_phone')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>

            </div>
          </div>

          <div className='border border-1 p-10 mb-10'>
            <h6>Nomor Rekening</h6>
            <hr></hr>
            <div className='row mb-6'>
              <div className='col-md-6'>

                <label className='form-label fw-bolder text-dark fs-6'>
                  <span className="required">Bank</span>
                </label>

                <select
                  {...formik.getFieldProps('bank')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Bank</option>

                  {banking?.map((item) => (
                    <>
                      <option value={item.name}>{item.name}</option>
                    </>
                  ))
                  }


                </select>

              </div>

              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nomor Rekening</span>
                  </label>

                  <input
                    placeholder='Nomor Rekening'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('account_number')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>

            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Pendidikan Trakhir </span>
                </label>
                <select
                  {...formik.getFieldProps('last_education')}
                  className='form-control bg-transparent'
                >
                  <option value={""}>Pilih Pendidikan Trakhir</option>
                  <option value={'SD'}>SD</option>
                  <option value={'SMP'}>SMP</option>
                  <option value={'SMA'}>SMA</option>
                  <option value={'SMK'}>SMK</option>
                  <option value={'D1'}>D1</option>
                  <option value={'D2'}>D2</option>
                  <option value={'D3'}>D3</option>
                  <option value={'S1'}>S1</option>
                  <option value={'S2'}>S2</option>
                  <option value={'S3'}>S3</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Jenis Kendaraan </span>
                </label>
                <select
                  {...formik.getFieldProps('owned_vehicle')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Jenis Kendaraan</option>
                  <option value={'Motor'}>Motor</option>
                  <option value={'Mobil'}>Mobil</option>
                </select>
              </div>
            </div>
          </div>

          <div className='row fv-row mb-8'>
            <div className='col-md-12'>
              <label className='form-label fw-bolder text-dark fs-6'>
                <span>Status</span>
              </label>

              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('is_active')}
              >
                <option value='true'>Aktif</option>
                <option value='false'>Tidak Aktif</option>
              </select>
              {formik.touched.is_active && formik.errors.is_active && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.is_active}</div>
                </div>
              )}
            </div>
          </div>


          {showApproval ?
            <div className='row fv-row mb-8'>
              <div className='col-md-12'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  <span>Approvel</span>
                </label>

                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('status')}
                >
                  <option value='1'>Approve</option>
                  <option value='0'>Tidak Approved</option>
                </select>
                {formik.touched.status && formik.errors.status && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.status}</div>
                  </div>
                )}
              </div>
            </div> : ""
          }

        </div>



        {/* begin::Form group */}
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Simpan'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
      <ToastContainer />
    </>
  )
}

export { UpdateSalesNonOrganic }
