import Cookies from 'js-cookie';


export const MapingAkses = (data: string): any => {
    const x = Cookies.get('menu');
    const decodedData = decodeURIComponent(x);
    const parsedObject = JSON.parse(decodedData);
    let result 
    parsedObject.forEach(item => {
        const name = item.name;

        if (name === data) {
            console.log("ini name", name)
            result = {
                read: item?.read,
                create: item?.create,
                update: item?.update,
                delete: item?.delete,
            };
        }
    });
   
    return result
}