/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { KTIcon } from '../../../../_metronic/helpers'
import { FilterMonth } from '../../../../_metronic/partials/content/dropdown/FilterMonth'
import { dateNowConvert, dateNowYMD } from '../../../../_metronic/helpers/dateHelpers'
import { CountDataDebiturStatus } from '../core/_model'
import { format, addDays, addWeeks, addMonths, subDays } from 'date-fns';
import { CountDebiturStatus } from '../core/_requests'

type Props = {
    className: string
}

const DebiturStatus: React.FC<Props> = ({ className }) => {

    const getMonthRange = () => {
        const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        return {
            start: startOfMonth,
            end: endOfMonth,
        };
    };


    const [selectedDate, setSelectedDate] = useState(new Date());
    const [data, setData] = useState<CountDataDebiturStatus>()
    const [dateIn, setdateIn] = useState(dateNowYMD())

    const start = format(getMonthRange().start, 'Y-M-d')
    const end = format(getMonthRange().end, 'Y-M-d')


    const fetchData = async (start: string, end: string) => {


        try {
            const request = await CountDebiturStatus(start, end);
            setData(request);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };



    const onchange = (x) => {
        if (x) {
            fetchData(x?.startDate, x?.endDate)
        }
    }

    useEffect(() => {
        fetchData(start, end)
    }, []);

    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bold text-dark'>Status Debitur</h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <FilterMonth onfilter={onchange} />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
                    {/* begin::Icon */}
                    <span className=' text-danger me-5'>
                        <KTIcon iconName='abstract-26' className='text-danger fs-1 me-5' />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                            Collect Data
                        </a>
                        <span className='text-muted fw-semibold d-block'></span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <span className='fw-bold text-danger py-1'>{data?.data?.collect_count} Debitur</span>
                    {/* end::Lable */}
                </div>
                {/* end::Item */}


                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
                    {/* begin::Icon */}
                    <span className=' text-success me-5'>
                        <KTIcon iconName='abstract-26' className='text-success fs-1 me-5' />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                            PROSES
                        </a>
                        <span className='text-muted fw-semibold d-block'></span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <span className='fw-bold text-success py-1'>{data?.data?.proses_count} Debitur</span>
                    {/* end::Lable */}
                </div>
                {/* end::Item */}

                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light-info rounded p-5'>
                    {/* begin::Icon */}
                    <span className=' text-info me-5'>
                        <KTIcon iconName='abstract-26' className='text-info fs-1 me-5' />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                            APPROVED
                        </a>
                        <span className='text-muted fw-semibold d-block'></span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <span className='fw-bold text-info py-1'>{data?.data?.approved_count}  Debitur</span>
                    {/* end::Lable */}
                </div>
                {/* end::Item */}

                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light-success rounded p-5 mt-5'>
                    {/* begin::Icon */}
                    <span className=' text-info me-5'>
                        <KTIcon iconName='abstract-26' className='text-info fs-1 me-5' />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                            AKAD
                        </a>
                        <span className='text-muted fw-semibold d-block'></span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <span className='fw-bold text-info py-1'>{data?.data?.akad_count}  Debitur</span>
                    {/* end::Lable */}
                </div>
                {/* end::Item */}

                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light-warning rounded mt-7 p-5 mb-7'>
                    {/* begin::Icon */}
                    <span className=' text-warning me-5'>
                        <KTIcon iconName='abstract-26' className='text-warning fs-1 me-5' />
                    </span>
                    {/* end::Icon */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                            BOOKING
                        </a>
                        <span className='text-muted fw-semibold d-block'></span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    <span className='fw-bold text-warning py-1'>{data?.data?.booking_count}  Debitur</span>
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { DebiturStatus }
