import React, { useState, useEffect } from 'react'
import { ListModel, Search, SearchModel } from '../core/_model'
import { Delete, Get } from '../core/_request'
import Pagination from '../../../helpers/components/KTPagination'
import { toImgaesURL } from '../../../helpers'
import { KTIcon } from '../../../helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom'


type Props = {
    className: string
    id: any
    inputType: string
    dataReload: any
}

const Tabel: React.FC<Props> = ({ className, id, inputType, dataReload }) => {

    const [data, setData] = useState<ListModel>()
    const [activePage, setActivePage] = useState(1);
    const [limitPage, setLimitPage] = useState(10);

    const fetchData = async (page = 1, limit = 10) => {
        const search: Search[] = [];

        search.push({
            key: "relasi_id",
            condition: "=",
            parameter: id
        })

        search.push({
            key: "type",
            condition: "=",
            parameter: inputType
        })

        const initialUser: SearchModel = {
            page: page,
            limit: limit,
            search: search
        };

        try {
            const request = await Get(initialUser);
            setData(request);
        } catch (error) {

        }
    };

    const handlePageChange = async (newPage) => {
        setActivePage(newPage);
        fetchData(newPage, limitPage)
    };

    const handleLimitChage = async (newlimit) => {
        setLimitPage(newlimit);
        setActivePage(1);
        fetchData(1, newlimit)
    };

    const HandleDelete = async (id) => {
        await Delete(id)
        fetchData(activePage, limitPage)
    };

    useEffect(() => {
        fetchData()
    }, [dataReload]);



    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr>
                                <th className='min-w-150px'>Nama</th>
                                <th className='min-w-140px'>File</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {data?.data.rows && data.data.rows.length > 0 ? (
                                data?.data.rows.map((employee, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className='text-dark fw-bold text-hover-primary fs-6'>
                                                {employee.name}
                                            </span>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                key="left"
                                                placement="left"
                                                overlay={<Tooltip id={`tooltip-left`}>View File</Tooltip>}
                                            >
                                                <a href={toImgaesURL(employee.file)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                    <KTIcon iconName='switch' className='fs-3' />

                                                </a>
                                            </OverlayTrigger>


                                            <Link
                                                to={"#"}
                                                onClick={() => HandleDelete(employee.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                                <KTIcon iconName='trash' className='fs-3' />
                                            </Link>
                                        </td>
                                    </tr>
                                ))

                            ) : (
                                <tr>
                                    <td colSpan={3}> </td>
                                </tr>
                            )}

                        </tbody>
                        {/* end::Table body */}
                    </table>
                    <Pagination
                        activePage={activePage}
                        totalPages={data?.data.total_pages ?? 1}
                        onPageChange={handlePageChange}
                        limit={limitPage}
                        onLimitChange={handleLimitChage}
                    />
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export { Tabel }
