import axios, { AxiosResponse } from 'axios'
import {ListDrowdown, GetDetailGlobal, ListUserAdmin } from './_model'

const API_URL = process.env.REACT_APP_API_URL

export const DROPDOWN_BANKING = `${API_URL}/master/banking/dropdown`
export const DROPDOWN_POSITION = `${API_URL}/master/position/dropdown`
export const DROPDOWN_AREABANKING = `${API_URL}/master/banking-area/dropdown`
export const DROPDOWN_BRANCHBANKING = `${API_URL}/master/banking-branch/dropdown`
export const DROPDOWN_ROLES = `${API_URL}/auth/role/dropdown`
export const DROPDOWN_SALESORGANIK = `${API_URL}/sales-non-organic/dropdown`
export const GETDETAIL = `${API_URL}/auth/admin/detail`
export const GET_DROPDDOWN_ADMIN = `${API_URL}/auth/admin/dropdown`
export const DROPDOWN_COMPANY = `${API_URL}/master/company/dropdown`

export const GetDropdownBanking = async (): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_BANKING}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}
export const GetDropdownPosition = async (): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_POSITION}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}
export const GetDropdownAreaBanking = async (id: any): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_AREABANKING}?banking_id=${id}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}

export const GetDropdownBranchBanking = async (id: any): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_BRANCHBANKING}?banking_area_id=${id}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}

export const GetProfiles = async (id: any): Promise<GetDetailGlobal> => {
  return axios
    .get(`${GETDETAIL}/${id}`)
    .then((d: AxiosResponse<GetDetailGlobal>) => d.data)
}


export const GetDropdownRole = async (): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_ROLES}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}

export const GetDropdownLead = async (id: any): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_SALESORGANIK}?npp_lead=${id}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}
export const GetDropdownCompany = async (): Promise<ListDrowdown> => {
  return axios
    .get(`${DROPDOWN_COMPANY}`)
    .then((d: AxiosResponse<ListDrowdown>) => d.data)
}

export const GetDropdownUserAdminApproval = async (key: any, search: any): Promise<ListUserAdmin> => {
  return axios
    .get(`${GET_DROPDDOWN_ADMIN}/${key}/${search}`)
    .then((d: AxiosResponse<ListUserAdmin>) => d.data)
}
