import axios, { AxiosResponse } from 'axios'
import { SalesModel, RequestDetail } from './_model'

const API_URL = process.env.REACT_APP_API_URL + '/sales-non-organic'

export const UPDATE = `${API_URL}/update`
export const DETAIL = `${API_URL}/detail`

export const SubmitUpdate = async (requestBody: SalesModel, id): Promise<any> => {
  return axios
    .put(`${UPDATE}/${id}`, requestBody)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((err) => err?.response?.data)
}

export const GetDetail = async (id: any): Promise<RequestDetail> => {
  return axios
    .get(`${DETAIL}/${id}`)
    .then((d: AxiosResponse<RequestDetail>) => d.data)
    .catch((err) => err?.response?.data);
}

