import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import Cookies from 'js-cookie';


export function MenuInner() {
  const intl = useIntl()
  console.log("ini data intl",intl);
  const bank = Cookies.get('bank');

  return (

    <>
      <MenuItem title={`Welcome to Bank ${bank} Digital Monitoring Platform , Your Portal to a Seamless Banking Experience !`} to='/dashboard' />
    </>
  )
}
