import React, { useState } from 'react';

const Pagination = ({ activePage, totalPages, onPageChange, limit, onLimitChange }) => {

    const [selectedLimit, setSelectedLimit] = useState(limit);

    const handleLimitChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setSelectedLimit(newLimit);
        onLimitChange(newLimit);
    };

    const renderPageItems = () => {
        const pageItems: JSX.Element[] = [];

        // Previous button
        pageItems.push(
            <li key="previous" className={`page-item ${activePage > 1 ? '' : 'disabled'}`}>
                <a className="page-link" href="#!" onClick={() => onPageChange(activePage - 1)}>
                    Previous
                </a>
            </li>
        );

        for (
            let i = activePage === totalPages ? totalPages - 3 : activePage === 1 ? activePage : activePage - 1;
            i <= (activePage === totalPages ? totalPages - 1 : activePage + 1);
            i++
        ) {
            if (i < totalPages && i > 0) {
                // Page number items
                pageItems.push(
                    <li key={i} className={`page-item ${activePage === i ? 'active' : ''}`}>
                        <a className="page-link" href="#!" onClick={() => onPageChange(i)}>
                            {i}
                        </a>
                    </li>
                );
            }
        }

        if (totalPages !== 1 && totalPages !== 0) {
            // Ellipsis
            pageItems.push(
                <li key="ellipsis" className="page-item">
                    <span className="page-link">...</span>
                </li>
            );
        }

        if (totalPages !== 1 && totalPages !== 0) {
            // Last page
            pageItems.push(
                <li key="last" className={`page-item ${activePage === totalPages ? 'active' : ''}`}>
                    <a className="page-link" href="#!" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </a>
                </li>
            );
        }

        // Next button
        pageItems.push(
            <li key="next" className={`page-item ${activePage < totalPages ? '' : 'disabled'}`}>
                <a className="page-link" href="#!" onClick={() => onPageChange(activePage + 1)}>
                    Next
                </a>
            </li>
        );

        return pageItems;
    };

    return (
        <div className="mt-4">

            <nav aria-label="Page navigation example">

                <ul className="pagination justify-content-end">
                    <select
                        value={selectedLimit}
                        onChange={handleLimitChange}
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        style={{ width: '75px', }}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>

                    {renderPageItems()}

                </ul>
            </nav>
        </div>
    );
}

export default Pagination;
