
export const dateNowDMY = (): string => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
}

export const dateNowYMD = (): string => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = currentDate.getFullYear();

    return `${year}-${month}-${day}`;
}
export const dateNowConvert = (originalDate): string => {
    var parts = originalDate.split("-");
    var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
}


export const dateNowDMYSubmit = (tanggal: string): string => {
    // Menghapus karakter 'T' pertama dan karakter 'T' setelahnya
    console.log(tanggal)
    try {
        if (tanggal === null) {
            return ""
        }

        if (tanggal === "") {
            return ""
        }
        tanggal = tanggal.replace(/T/g, '');
        // Parsing tanggal ke dalam format yang baru
        const date = new Date(tanggal);

        // Membuat string format yang diinginkan (ISO string)
        const formattedDate = date.toISOString();

        return formattedDate;

    } catch (error) {
        return ""
    }

}

export const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('id-ID', options);
};

export const statusPageDebitur = (status) => {

    switch (status) {
        case 1:

            return "Collect Data"
        case 2:

            return "Proses"
        case 3:

            return "Approved"
        case 4:

            return "Akad"
        case 5:

            return "Booking"
        case 6:

            return "Cancel"
        default:
            return "Status Belum DI tentukan"
    }
};
