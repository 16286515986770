/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CountSalesNonOrganic } from '../core/_requests'

import { KTIcon, formatToRupiahIDN, toImgaesURL } from '../../../../_metronic/helpers'
import { FilterMonth } from '../../../../_metronic/partials/content/dropdown/FilterMonth'
import { format, addDays, addWeeks, addMonths, subDays } from 'date-fns';
import { dateNowConvert } from '../../../../_metronic/helpers/dateHelpers'
import { DataSalesNon } from '../core/_model'

type Props = {
    className: string
    items?: number
}

const Sales: React.FC<Props> = ({ className, items = 6 }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());


    const getMonthRange = () => {
        const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        return {
            start: startOfMonth,
            end: endOfMonth,
        };
    };
    const [start, setstart] = useState(format(getMonthRange().start, 'Y-M-d'));
    const [end, setend] = useState(format(getMonthRange().end, 'Y-M-d'));

    const [data, setData] = useState<DataSalesNon[]>([])


    const fetchData = async (start: string, end: string) => {


        try {
            const request = await CountSalesNonOrganic(start, end);
            setData(request.data ?? []);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };

    const onchange = (x) => {
        if (x) {
            fetchData(x?.startDate, x?.endDate)
            setstart(x?.startDate)
            setend(x?.endDate)
        }

    }

    useEffect(() => {
        fetchData(start, end)
    }, []);;

    return (
        <div className='card card-xl-stretch mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-dark'>Top Sales</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>{dateNowConvert(start)} - {dateNowConvert(end)}</span>
                </h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <FilterMonth onfilter={onchange} />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-2'>
                {data.map((item, index) => {
                    return (
                        <>
                            {/* begin::Item */}
                            <div className='d-flex align-items-center mb-7'>
                                {/* begin::Avatar */}
                                <div className='symbol symbol-50px me-5'>
                                    <img src={toImgaesURL(item?.sales_non_organic?.foto)} className='' alt='' />
                                </div>
                                {/* end::Avatar */}
                                {/* begin::Text */}
                                <div className='flex-grow-1'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {item?.sales_non_organic?.name}
                                    </a>
                                    <span className='text-muted d-block fw-semibold'>Bonus Rp{formatToRupiahIDN(item?.total_insentif.toString())}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                            {/* end::Item */}
                        </>
                    )
                })

                }


            </div>
            {/* end::Body */}
        </div>
    )
}

export { Sales }
