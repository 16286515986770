/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../helpers'
import { DetailPage } from './DetailPage'
import { UpdateSalesNonOrganic } from '../update/UpdateSalesNonOrganic'

const DetailsSalesNonOrganic = () => {
    const [viewEdit, setViewEdit] = useState<boolean>(false)
    const chnageButton = () => {
        setViewEdit(!viewEdit)
    }

    
    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Profile Details</h3>
                    </div>

                    <Link to='' onClick={chnageButton} className='btn btn-primary align-self-center'>
                        {viewEdit ? 'Detail Sales' : 'Edit Sales'}


                    </Link>
                </div>
                {viewEdit ? <UpdateSalesNonOrganic /> : <DetailPage />}




            </div>
        </>
    )
}

export { DetailsSalesNonOrganic }
