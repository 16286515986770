/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTIcon, formatToRupiahIDN } from '../../../../_metronic/helpers'
import { dateNowConvert, dateNowYMD } from '../../../../_metronic/helpers/dateHelpers'
import axios from 'axios';
import Cookies from 'js-cookie';
import { ListModel, Search, SearchModel } from '../core/_model'
import { GetDebitur } from '../core/_requests'
import { FilterDate } from '../../../../_metronic/partials/content/dropdown/FilterDate'
import { GetProfileDetail } from '../../../../_metronic/helpers/global-fetch/profile';

type Props = {
    className: string
}

const ListDebitur: React.FC<Props> = ({ className }) => {
    const role = Cookies.get('role');
    const [data, setData] = useState<ListModel>()
    const [dateIn, setdateIn] = useState(dateNowYMD())
    const fetchData = async (search: Search[], page = 1, limit = 10) => {


        const initialUser: SearchModel = {
            page: 1,
            limit: 5,
            search: search
        };

        try {
            const request = await GetDebitur(initialUser);
            setData(request);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };

    const onchange = async (x) => {
        setdateIn(x)
        const search: Search[] = [
            {
                key: "created_at::date",
                condition: "=",
                parameter: x
            },
        ]

        if (role === "3" || role === "4") {
            const pr = await GetProfileDetail();
            if (role === "3") {
                search.push({
                    key: "insert_npp",
                    condition: "=",
                    parameter: pr.data.profile.npp
                });
            }

            if (role === "4") {
                search.push({
                    key: "profile_npp",
                    condition: "=",
                    parameter: pr.data.profile.npp
                });
            }
        }



        console.log("ini role", role)
        fetchData(search)
    }

    useEffect(() => {
        onchange(dateNowYMD())
    }, []);

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0'>

                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-dark'>Debitur Register</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>{dateNowConvert(dateIn)}</span>
                </h3>

                <span></span>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <FilterDate onchange={onchange} />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-2'>
                {data?.data.rows && data.data.rows.length > 0 ? (
                    <>
                        {data?.data.rows.map((item, index) => {
                            return (
                                <>
                                    {/* begin::Item */}
                                    <div className='d-flex align-items-center mb-7'>
                                        {/* begin::Text */}
                                        <span className="fa-stack">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-icon-of-your-choice fa-stack-1x fa-inverse"></i>
                                        </span>
                                        <div className='flex-grow-1'>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.name}
                                            </a>
                                            <span className='text-muted d-block fw-semibold'>Rp: {formatToRupiahIDN(item.submission_amount)}</span>
                                        </div>
                                        {/* end::Text */}
                                    </div>
                                    {/* end::Item */}
                                </>
                            )
                        })

                        }
                    </>

                ) : (
                    <></>
                )}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ListDebitur }
