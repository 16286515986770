/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, formatToRupiahIDN, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CountSaleskpi } from '../core/_requests'
import axios from 'axios'
import { DataSalesKPI } from '../core/_model'


type Props = {
    className: string
}

const SalesKPI: React.FC<Props> = ({ className }) => {
    const [data, setData] = useState<DataSalesKPI[]>()

    const fetchData = async () => {
        try {
            const request = await CountSaleskpi();
            console.log(request)
            setData(request.data ?? [])
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };


    useEffect(() => {
        fetchData()
    }, []);
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Sales KPI</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
                </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Name</th>
                                <th className='min-w-150px'>Total Debitur</th>
                                <th className='min-w-140px'>Total Payment</th>
                                <th className='min-w-120px'>Pesentasi Take Over dan Baru</th>
                                <th className='min-w-100px text-end'>Persentasi Top Up</th>
                                <th className='min-w-100px text-end'>Persentasi Prapensiun</th>
                                <th className='min-w-100px text-end'>Total Sekor</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>

                            {
                                data?.map((item, index) => {
                                    return (
                                        <>
                                            <tr className='text-center'>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        {item.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {item.total_debitur}

                                                    </a>

                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        Rp{formatToRupiahIDN(item.total.toString())}

                                                    </a>

                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {item.percentage_top_new_take_over}%

                                                    </a>

                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {item.percentage_top_up}%

                                                    </a>

                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {item.percentage_prapensiun}%

                                                    </a>

                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {item.total_skor_kinerja}

                                                    </a>

                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }



                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export { SalesKPI }
