import React, { useEffect, useState } from 'react'
import { GetDetail } from '../core/_request';
import { useParams } from "react-router-dom";
import { RequestDetail } from '../core/_model';

const DetailPage = () => {
    const { id } = useParams();
    const [showApproval, setshowApproval] = useState<boolean>()
    const [dataSales, setdataSales] = useState<RequestDetail>()

    const getDetails = async () => {
        try {
            const response = await GetDetail(id);
            if (response.status) {

                setdataSales(response)
                let viewApproval = false;
                for (const item of response?.data?.approval) {
                    if (item.is_status === true) {
                        viewApproval = true;
                        break;
                    }
                }
                
                setshowApproval(viewApproval);

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        getDetails();
    }, [])
    return (
        <div className='card-body p-9'>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'> Nama </label>
                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{dataSales?.data.detail?.name}</span>
                </div>
            </div>
            <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Nomor KTP</label>

                <div className='col-lg-8'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.id_card}</span>
                </div>
            </div>
            <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Nomor NPWP</label>

                <div className='col-lg-8'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.npwp}</span>
                </div>
            </div>
            <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Nama Ibu Kandung</label>

                <div className='col-lg-8'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.biological_mother_name}</span>
                </div>
            </div>
            <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Status Tempat Tinggal Dan Lama Tinggal</label>

                <div className='col-lg-8'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.residence} {dataSales?.data?.detail?.length_of_stay != '' ? 'Lama Tinggal' : ''}   {dataSales?.data?.detail?.length_of_stay} </span>
                </div>
            </div>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Type Sales</label>

                <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.type}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Lead Sales NPP</label>

                <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>{dataSales?.data?.detail?.npp_lead}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                    Email
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bolder fs-6 me-2'>{dataSales?.data?.detail?.email}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                    Nomor Hanphone
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bolder fs-6 me-2'>{dataSales?.data?.detail?.phone}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Alamat Sesuai KTP</label>

                <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bolder fs-6 me-2'>{dataSales?.data?.detail?.address_id_card}</span>
                </div>
            </div>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Alamat Domisili</label>

                <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bolder fs-6 me-2'>{dataSales?.data?.detail?.address_domicile}</span>
                </div>
            </div>


            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Tempat, Tanggal Lahir</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {dataSales?.data?.detail?.place_of_birth} - {dataSales?.data?.detail?.date_of_birth}
                    </span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Pendidikan Terakhir</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {dataSales?.data?.detail?.last_education}
                    </span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Jenis Kendaraan</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {dataSales?.data?.detail?.owned_vehicle}
                    </span>
                </div>
            </div>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Aproval</label>

                <div className='col-lg-8'>
                    {
                        dataSales?.data?.detail?.status === 0 ?
                            (<span className='badge badge-light-danger'>Belum Di Approve</span>)
                            :
                            (<span className='badge badge-light-success'>Approve</span>)

                    }
                </div>
            </div>
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Status</label>

                <div className='col-lg-8'>
                    {
                        !dataSales?.data?.detail?.is_active ?
                            (<span className='badge badge-light-danger'>Tidak Aktif</span>)
                            :
                            (<span className='badge badge-light-success'>Aktif</span>)

                    }
                </div>
            </div>



            <div className='bg-light-warning rounded border-warning border border-dashed p-6'>
                <p>Kontak Keluarga</p>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Nama Keluarga</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.family_name}
                        </span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Hubungan</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.family_connection}
                        </span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Alamat</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.family_address}
                        </span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Nomor Handphone</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.family_phone}
                        </span>
                    </div>
                </div>
            </div>

            <div className='mt-5 bg-light-warning rounded border-warning border border-dashed p-6'>
                <p>Nomor Rekening</p>

                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Bank</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.bank}
                        </span>
                    </div>
                </div>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Nomor Rekening</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {dataSales?.data?.detail?.account_number}
                        </span>
                    </div>
                </div>
            </div>
            <div className='mt-5 bg-light-warning rounded border-warning border border-dashed p-6'>
                <p> {!showApproval ? 'Menungu Approval Dari' : 'Diapprove Oleh'}</p>

                {dataSales?.data?.approval.map((item, index) => {
                    return (
                        <>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{item.npp_user}</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>
                                        {
                                            item.is_status ?
                                                (<span className='badge badge-light-success'>Approve</span>)
                                                : ""
                                        }
                                    </span>
                                </div>
                            </div>
                        </>

                    )

                })}

            </div>
        </div>
    )
}
export { DetailPage }