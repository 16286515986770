import axios, { AxiosResponse } from 'axios'
import { SalesModel } from './_model'
import HeaderConfig from '../../../../../helpers/HeaderConfig'

const API_URL = process.env.REACT_APP_API_URL + '/sales-non-organic'

export const CREATE = `${API_URL}/create`

export const SubmitCreate = async (requestBody: SalesModel): Promise<any> => {
  return axios
    .post(`${CREATE}`, requestBody, HeaderConfig)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((err) => err?.response?.data)
}

