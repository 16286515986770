import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const SalesPage = lazy(() => import('../modules/sales/sales/SalesPage'))
  const SalesLeadPage = lazy(() => import('../modules/sales/salesLead/SalesLead'))
  const NonOrganikSalesPage = lazy(() => import('../modules/sales/non-organik/NonOrganikSalesPage'))
  const PaymentPage = lazy(() => import('../modules/finance/payment/PaymentPage'))
  const DebiturPage = lazy(() => import('../modules/debitur/DebiturPage'))
  const InsentifPage = lazy(() => import('../modules/finance/insentif/InsentifPage'))
  const RolesPage = lazy(() => import('../modules/user_management/roles/RolesPage'))
  const UsersPages = lazy(() => import('../modules/user_management/users/UsersPage'))
  const SalesPageDetail = lazy(() => import('../pages/sales-detail/SalesDetailPage'))
  const PositionPage = lazy(() => import('../modules/master/position/PositionPage'))
  const BankingPage = lazy(() => import('../modules/master/banking/BankingPage'))
  const CompanyPage = lazy(() => import('../modules/master/company/CompanyPage'))
  const SimulasiPage = lazy(() => import('../modules/master/simulasi/SimulasiPage'))
  const BankingBranchPage = lazy(() => import('../modules/master/bankingBranch/BankingBranchPage'))
  const BankingAreaPage = lazy(() => import('../modules/master/bankingArea/BankingAreaPage'))
  const GajiPage = lazy(() => import('../modules/finance/gaji/GajiPage'))
  const PageSimulasi = lazy(() => import('../modules/simulasi/PageSimulasi'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='sales/*'
          element={
            <SuspensedView>
              <SalesPage />
            </SuspensedView>
          }
        />

        <Route
          path='pembayaran/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='debitur/*'
          element={
            <SuspensedView>
              <DebiturPage />
            </SuspensedView>
          }
        />

        <Route
          path='insentif/*'
          element={
            <SuspensedView>
              <InsentifPage />
            </SuspensedView>
          }
        />
        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPages />
            </SuspensedView>
          }
        />
        <Route
          path='page-sales/*'
          element={
            <SuspensedView>
              <SalesPageDetail />
            </SuspensedView>
          }
        />
        <Route
          path='position/*'
          element={
            <SuspensedView>
              <PositionPage />
            </SuspensedView>
          }
        />
         <Route
          path='banking/*'
          element={
            <SuspensedView>
              <BankingPage />
            </SuspensedView>
          }
        />
        <Route
          path='area-banking/*'
          element={
            <SuspensedView>
              <BankingAreaPage />
            </SuspensedView>
          }
        />
        <Route
          path='gaji/*'
          element={
            <SuspensedView>
              <GajiPage />
            </SuspensedView>
          }
        />
        <Route
          path='/non-organik/*'
          element={
            <SuspensedView>
              <NonOrganikSalesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/company/*'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='/branch-banking/*'
          element={
            <SuspensedView>
              <BankingBranchPage />
            </SuspensedView>
          }
        />
        <Route
          path='/simulasi/*'
          element={
            <SuspensedView>
              <PageSimulasi />
            </SuspensedView>
          }
        />
        <Route
          path='/master-simulasi/*'
          element={
            <SuspensedView>
              <SimulasiPage />
            </SuspensedView>
          }
        />
        <Route
          path='/lead-sales/*'
          element={
            <SuspensedView>
              <SalesLeadPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
