import axios, { AxiosResponse } from 'axios'
import { LampiranModel, ListModel, SearchModel } from './_model'
import HeaderConfig from '../../../helpers/HeaderConfig'

const API_URL = process.env.REACT_APP_API_URL + '/lampiran'

export const CREATE = `${API_URL}/create`
export const LIST = `${API_URL}/list`
export const DELETE = `${API_URL}/delete`

export const SubmitCreate = async (requestBody: LampiranModel): Promise<any> => {
  return axios
    .post(`${CREATE}`, requestBody, HeaderConfig)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((err) => err?.response?.data)
}

export const Get = async (requestBody: SearchModel): Promise<ListModel> => {

  return axios
    .post(`${LIST}`, requestBody, HeaderConfig)
    .then((d: AxiosResponse<ListModel>) => d.data)
    .catch((err) => err?.response?.data)
}
export const Delete = async (id: any): Promise<ListModel> => {

  return axios
    .delete(`${DELETE}/${id}`)
    .then((d: AxiosResponse<ListModel>) => d.data)
    .catch((err) => err?.response?.data)
}


