import React, { useState, useEffect } from 'react'
import { SalesModel, Value as initialValues, DataSchema } from './core/_model'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { GetDropdownBanking, GetDropdownUserAdminApproval, GetDropdownAreaBanking } from '../../../../helpers/global-fetch/dropdown'
import { DataDrowpdown, ListDrowpdownMultiSelect } from '../../../../helpers/global-fetch/_model'
import { SubmitCreate } from './core/_request';
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import { dateNowDMYSubmit } from '../../../../helpers/dateHelpers'
import { useParams } from "react-router-dom";
import MultiSelect from 'multiselect-react-dropdown';


const CreateSalesNonOrganic = () => {
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [banking, setBanking] = useState<DataDrowpdown[]>()
  const [bankingArea, setBankingArea] = useState<DataDrowpdown[]>()
  const [adminApproval, setadminApproval] = useState<ListDrowpdownMultiSelect[]>()
  const navigate = useNavigate();
  const { id } = useParams();


  const [selectedOptions, setSelectedOptions] = useState<ListDrowpdownMultiSelect[]>();

  const handleSelect = (selectedList) => {
    setSelectedOptions(selectedList);
  };

  const handleRemove = (selectedList) => {
    setSelectedOptions(selectedList);
  };




  const [loading, setLoading] = useState(false)
  const formik = useFormik<SalesModel>({
    initialValues,
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      setLoading(true)
      values.phone = values.phone.toString()
      values.id_card = values.id_card.toString()
      values.npwp = values.npwp.toString()
      values.npp_lead = values.npp_lead.toString()
      values.foto = base64Image == null ? "" : base64Image
      values.banking_id = parseInt(values.banking_id.toString())
      values.banking_area_id = parseInt(values.banking_area_id.toString())
      const resultUserAdmin = selectedOptions?.map(item => ({
        type: "sales-non-organic",
        npp_user: item.id,
        is_status: false,
      }))
      values.approval = resultUserAdmin ?? []
      const result = await SubmitCreate(values)
      setLoading(false)

      if (result?.status) {
        toast.success(result?.message);
        setTimeout(() => {
          if (id === "0") {
            navigate('/non-organik/views');
          } else {
            navigate('/sales/sales-list/' + id)
          }
        }, 1000)
      } else {
        toast.error(result?.message);
      }

    },
  })

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = e.target?.result as string;
        setBase64Image(base64Data);
      };

      reader.readAsDataURL(file);
    }
  };


  const GetBanking = async () => {
    try {
      const response = await GetDropdownBanking();
      setBanking(response?.data ?? [])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const GetDropdownsUserAdminApproval = async () => {
    try {
      const response = await GetDropdownUserAdminApproval("profiles.is_approval", "true");
      const dataResult = response?.data ?? [];
      const mappedData = dataResult.map(item => ({
        id: item.npp,
        name: item.name,
      }));

      setadminApproval(mappedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const GetBankingArea = async (x) => {
    try {
      formik.setFieldValue('banking_id', x);
      const response = await GetDropdownAreaBanking(x);
      setBankingArea(response?.data ?? [])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    if (id !== "0") {
      formik.setFieldValue('npp_lead', id ?? '');

    }
    GetBanking();
    GetDropdownsUserAdminApproval();

  }, []);

  return (
    <>
      <form
        className='form p-4'
        noValidate
        onSubmit={formik.handleSubmit}
      >

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='row fv-row mb-8'>
          <div className="mt-2 fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>FOTO</span>
            </label>
            <input
              placeholder='foto'
              type='file'
              autoComplete='off'
              onChange={handleFileUpload}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.foto && formik.errors.foto,
                },
                {
                  'is-valid': formik.touched.foto && !formik.errors.foto,
                }
              )}
            />
            {formik.touched.foto && formik.errors.foto && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.foto.toString()}</span>
                </div>
              </div>
            )}
            {base64Image && (
              <div className='mt-2'>
                <img style={{
                  width: '10%',
                  height: '10%'
                }} src={base64Image} alt="foto upload users admin" />
              </div>
            )}
          </div>


          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Nomor Kartu Keluarga</span>
            </label>

            <input
              placeholder='Nomor Kartu Keluarga'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('family_card_number')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.family_card_number && formik.errors.family_card_number,
                },
                {
                  'is-valid': formik.touched.family_card_number && !formik.errors.family_card_number,
                }
              )}
            />
            {formik.touched.family_card_number && formik.errors.family_card_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.family_card_number}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Type Sales </span>
            </label>


            <select
              {...formik.getFieldProps('type')}
              className='form-control bg-transparent'
            >
              <option value={''}>Pilih Type Sales</option>
              <option value={'Internal'}>Internal</option>
              <option value={'External'}>External</option>
            </select>

            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Npp</span>
            </label>

            <input
              placeholder='Npp'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('npp')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.npp && formik.errors.npp,
                },
                {
                  'is-valid': formik.touched.npp && !formik.errors.npp,
                }
              )}
            />
            {formik.touched.npp && formik.errors.npp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.npp}</span>
                </div>
              </div>
            )}
          </div>
          

          <div className='row mb-10'>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">NPP Atasan</span>
                </label>
                <input
                  readOnly={id !== "0"}
                  placeholder='NPP Atasan'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('npp_lead')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.npp_lead && formik.errors.npp_lead,
                    },
                    {
                      'is-valid': formik.touched.npp_lead && !formik.errors.npp_lead,
                    }
                  )}
                />
                {formik.touched.npp_lead && formik.errors.npp_lead && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.npp_lead}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span className="required">Nama</span>
                </label>
                <input
                  placeholder='Nama User'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Nomor NIK</span>
                </label>

                <input
                  placeholder='nomor NIK'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('id_card')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.id_card && formik.errors.id_card,
                    },
                    {
                      'is-valid': formik.touched.id_card && !formik.errors.id_card,
                    }
                  )}
                />
                {formik.touched.id_card && formik.errors.id_card && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.id_card}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span >Nomor NPWP</span>
                </label>

                <input
                  placeholder='nomor NPWP'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('npwp')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.npwp && formik.errors.npwp,
                    },
                    {
                      'is-valid': formik.touched.npwp && !formik.errors.npwp,
                    }
                  )}
                />
                {formik.touched.npwp && formik.errors.npwp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.npwp}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Email</span>
                </label>

                <input
                  placeholder='Nama Email'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.email && formik.errors.email,
                    },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='col-md-6'>

              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Nomor Handphone</span>
                </label>

                <input
                  placeholder='Nomor Handphone'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('phone')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.phone && formik.errors.phone,
                    },
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>


          <div className='col-md-12 mt-3'>

            <label className='form-label fw-bolder text-dark fs-6'>
              Pilih Penempatan Sales
            </label>

            <select
              {...formik.getFieldProps('banking_id')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.banking_id && formik.errors.banking_id,
                },
                {
                  'is-valid': formik.touched.banking_id && !formik.errors.banking_id,
                }
              )}
              onChange={(e) => GetBankingArea(e.target.value)} // Set the selected value in state
            >
              <option value=''>Pilih Banking</option>
              {banking?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))
              }


            </select>
            {formik.touched.banking_id && formik.errors.banking_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.banking_id}</div>
              </div>
            )}
          </div>

          <div className='col-md-12 mt-10'>

            <label className='form-label fw-bolder text-dark fs-6'>
              Pilih Wilayah Penempatan
            </label>

            <select
              {...formik.getFieldProps('banking_area_id')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.banking_area_id && formik.errors.banking_area_id,
                },
                {
                  'is-valid': formik.touched.banking_area_id && !formik.errors.banking_area_id,
                }
              )}
            >
              <option value=''>Pilih Area Banking</option>
              {bankingArea?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))
              }


            </select>
            {formik.touched.banking_area_id && formik.errors.banking_area_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.banking_area_id}</div>
              </div>
            )}
          </div>



          <div className="fv-row mt-10 mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Alamat Sesuai KTP</span>
            </label>

            <input
              placeholder='Alamat Sesuai KTP'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('address_id_card')}
              className='form-control bg-transparent'
            />
          </div>


          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Alamat Sesuai Domisili</span>
            </label>

            <input
              placeholder='Alamat Sesuai KTP'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('address_domicile')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Tempat Lahir</span>
                </label>

                <input
                  placeholder='Tempat Lahir'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('place_of_birth')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Tanggal Lahir</span>
                </label>

                <input
                  placeholder='Tanggal Lahir'
                  type='date'
                  autoComplete='off'
                  {...formik.getFieldProps('date_of_birth')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>

          </div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="fs-5 fw-bold form-label mb-2">
              <span>Nama Ibu Kandung</span>
            </label>

            <input
              placeholder='Nama Ibu Kandung'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('biological_mother_name')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Status Tempat Tinggal </span>
                </label>


                <select
                  {...formik.getFieldProps('residence')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Status Tempat Tinggal</option>
                  <option value={'Sewa'}>Sewa</option>
                  <option value={'Sendiri'}>Sendiri</option>
                  <option value={'Orang Tua'}>Orang Tua</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Lama Tinggal</span>
                </label>

                <input
                  placeholder='Lama Tinggal'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('length_of_stay')}
                  className='form-control bg-transparent'
                />
              </div>
            </div>
          </div>

          <div className='border border-1 p-10 mb-10'>
            <h6>Kontak Keluarga</h6>
            <hr></hr>
            <div className='row mb-10'>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nama Keluarga</span>
                  </label>

                  <input
                    placeholder='Nama Keluarga'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_name')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Hubungan </span>
                  </label>


                  <select
                    {...formik.getFieldProps('family_connection')}
                    className='form-control bg-transparent'
                  >
                    <option value={''}>Pilih Hubungan</option>
                    <option value={'Orang Tua'}>Orang Tua</option>
                    <option value={'Saudara Kandung'}>Saudara Kandung</option>
                    <option value={'Anak'}>Anak</option>
                    <option value={'Kake'}>Kake</option>
                    <option value={'Nenek'}>Nenek</option>
                    <option value={'Paman'}>Paman</option>
                    <option value={'Bibi'}>Bibi</option>
                    <option value={'Sepupu'}>Sepupu</option>
                    <option value={'Mertua'}>Mertua</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='row mb-10'>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Alamat</span>
                  </label>

                  <input
                    placeholder='Alamat'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_address')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nomor Handphone</span>
                  </label>

                  <input
                    placeholder='Nomor Handphone'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('family_phone')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>

            </div>
          </div>

          <div className='border border-1 p-10 mb-10'>
            <h6>Nomor Rekening</h6>
            <hr></hr>
            <div className='row mb-6'>
              <div className='col-md-6'>

                <label className='form-label fw-bolder text-dark fs-6'>
                  <span className="required">Bank</span>
                </label>

                <select
                  {...formik.getFieldProps('bank')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Bank</option>

                  {banking?.map((item) => (
                    <>
                      <option value={item.name}>{item.name}</option>
                    </>
                  ))
                  }


                </select>

              </div>

              <div className='col-md-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold form-label mb-2">
                    <span>Nomor Rekening</span>
                  </label>

                  <input
                    placeholder='Nomor Rekening'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('account_number')}
                    className='form-control bg-transparent'
                  />
                </div>
              </div>

            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Pendidikan Trakhir </span>
                </label>
                <select
                  {...formik.getFieldProps('last_education')}
                  className='form-control bg-transparent'
                >
                  <option value={""}>Pilih Pendidikan Trakhir</option>
                  <option value={'SD'}>SD</option>
                  <option value={'SMP'}>SMP</option>
                  <option value={'SMA'}>SMA</option>
                  <option value={'SMK'}>SMK</option>
                  <option value={'D1'}>D1</option>
                  <option value={'D2'}>D2</option>
                  <option value={'D3'}>D3</option>
                  <option value={'S1'}>S1</option>
                  <option value={'S2'}>S2</option>
                  <option value={'S3'}>S3</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Jenis Kendaraan </span>
                </label>
                <select
                  {...formik.getFieldProps('owned_vehicle')}
                  className='form-control bg-transparent'
                >
                  <option value={''}>Pilih Jenis Kendaraan</option>
                  <option value={'Motor'}>Motor</option>
                  <option value={'Mobil'}>Mobil</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row mb-10'>
            <div className='col-md-12'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold form-label mb-2">
                  <span>Pilih Approval </span>
                </label>
                <div>
                  <MultiSelect
                    options={adminApproval}
                    selectedValues={selectedOptions}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    displayValue="name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* begin::Form group */}
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Simpan'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
      <ToastContainer />
    </>

  )
}

export { CreateSalesNonOrganic }
