/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTIcon } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { Dropdown1 } from '../../../../_metronic/partials'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { CountDebiturRegister } from '../core/_requests'
import axios from 'axios'
import { FilterYear } from '../../../../_metronic/partials/content/dropdown/FilterYear'

type Props = {
    className: string
    chartColor: string
    chartHeight: string
}

const DebiturRegister: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const [arrMonth, setArrMonth] = useState<string[]>([])
    const [arrNilai, setArrNilai] = useState<string[]>([])
    const { mode } = useThemeMode()
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight, arrNilai, arrMonth))
        if (chart1) {
            chart1.render()
        }

        return chart1
    }

    const fetchData = async (year: number) => {

        try {
            const request = await CountDebiturRegister(year);

            const month: string[] = [];
            const nilai: string[] = [];
            for (const i of request.data) {
                month.push(i.month_name);
                nilai.push(i.registration_count);
            }

            setArrMonth(month)
            setArrNilai(nilai)


            console.log(request)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errResp = error.response;
                console.log(errResp?.data)
            }
        }
    };
    const onchange = async (x) => {
      console.log(x)
      fetchData(parseInt(x));
    };

    useEffect(() => {
        fetchData(0);
    }, [mode]);

    useEffect(() => {
        const chart1 = refreshChart();
        return () => {
            if (chart1) {
                chart1.destroy();
            }
        }
    }, [chartRef, arrMonth, arrNilai]);

    return (
        <div className={`card ${className}`}>
            {/* begin::Beader */}
            <div className='card-header border-0 py-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Debitur Perbulan</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <FilterYear onchange={onchange} />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
                {/* begin::Chart */}
                <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}

        </div>
    )
}

const chart1Options = (chartColor: string, chartHeight: string, arrNilai: any, arrMont: any): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-800')
    const strokeColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor) as string
    const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

    return {
        series: [
            {
                name: 'Register Debitur',
                data: arrNilai,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: chartHeight,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        fill1: {
            type: 'gradient',
            opacity: 1,
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 0.375,
                stops: [25, 50, 100],
                colorStops: [],
            },
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor],
        },
        xaxis: {
            categories: arrMont,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            min: 0,
            max: 65,
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' debitur'
                },
            },
        },
        colors: [lightColor],
        markers: {
            colors: [lightColor],
            // @ts-ignore
            strokeColor: [baseColor],
            strokeWidth: 3,
        },
    }
}

export { DebiturRegister }
